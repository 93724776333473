import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { Box } from "@mui/material";
const UserRoutes = React.lazy(() => import("./routes/UserRoutes"));
import "smart-webcomponents-react/source/styles/smart.default.css";

// Notification context
export const NotificationContext = React.createContext();

function App() {
  React.useEffect(() => {
    //  Notification requestPermission code
    const permission = async () => {
      if (!("Notification" in window)) {
        console.log("This browser does not support notification");
        return;
      }

      if (!("serviceWorker" in navigator)) {
        console.log("This browser does not support service worker");
        return;
      }

      if (!("PushManager" in window)) {
        console.log("This browser does not support push notification");
        return;
      }
      if (!window) return;

      const Notification = window?.Notification;
      const permission =
        Notification?.permission === "granted"
          ? Notification?.permission
          : await Notification?.requestPermission();

      if (permission === "granted") {
        console.log("Notification permission granted");
      }
      if (permission === "denied") {
        console.log("Notification permission denied");
      }
    };
    permission();

    return () => {
      if (!window) return;
      if ("caches" in window) {
        caches.keys().then(function (keyList) {
          // console.log(keyList);
          return Promise.all(
            keyList.map(function (key) {
              return caches.delete(key);
            })
          );
        });
      }
      if (
        window.navigator &&
        navigator.serviceWorker &&
        "serviceWorker" in navigator
      ) {
        navigator.serviceWorker
          .getRegistrations()
          .then(function (registrations) {
            for (let registration of registrations) {
              registration.unregister();
            }
          });
      }
      //for internet explorer
      if (navigator.serviceWorker) {
        navigator.serviceWorker
          .getRegistrations()
          .then(function (registrations) {
            for (let idx in registrations) {
              registrations[idx].unregister();
            }
          });
      }
    };
  }, []);

  return (
    <Box
      sx={{
        background: "background.default",
      }}
    >
      <Suspense
        fallback={
          <Box
            sx={[
              {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                backgroundColor: "background.default",
              },
            ]}
          >
            {/* <img src={loader3} alt="loading" /> */}
            {/* <img src={loaders[Math.floor(Math.random() * loaders.length)]} alt="loading" /> */}
            <span className="loader" />
          </Box>
        }
      >
        <BrowserRouter>
          <UserRoutes />
        </BrowserRouter>
      </Suspense>
    </Box>
  );
}

export default App;

import { ThemeProvider } from "@mui/material/styles";
import { useCookie } from "browser_storage_hook_react";
import { createContext, useEffect, useState } from "react";
import { theme, lightTheme } from "../../theme-config/theme";
import useMediaQuery from "@mui/material/useMediaQuery";

// import { theDarkTheme, theLightTheme } from "../../theme-config/theme";

export const ThemeContext = createContext();

const ThemeContextProvider = (props) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const [Ctheme, setCTheme] = useState(theme);
  const [drawerOpen, setDrawerOpen] = useState(false);
  // const [manualDark, setManualDark] = useLocalStorage("manualDark", false);
  // const [isDark, setDark] = useLocalStorage("toggleMode", true);
  // const [systemDark, setSystemDark] = useLocalStorage("systemDark", false);
  const [manualDark, setManualDark] = useCookie("manualDark", true);
  const [isDark, setDark] = useCookie("toggleMode", false);
  const [systemDark, setSystemDark] = useCookie("systemDark", false);

  const setHandleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const changeTheme = () => {
    isDark ? setCTheme(theme) : setCTheme(lightTheme);
    setDark(!isDark);
  };

  useEffect(() => {
    isDark ? setCTheme(theme) : setCTheme(lightTheme);

    if (prefersDarkMode && systemDark) {
      setDark(true);
    } else if (!prefersDarkMode && systemDark) {
      setDark(false);
    } else if (manualDark) {
      setDark(isDark);
    }
    // console.log("isDark", prefersDarkMode);
  }, [isDark, manualDark, prefersDarkMode, setDark, systemDark]);

  const value = {
    Ctheme,
    drawerOpen,
    setHandleDrawer,
    changeTheme,
    isDark,
    manualDark,
    setManualDark,
    systemDark,
    setSystemDark,
    setDark,
  };
  return (
    <ThemeProvider theme={Ctheme}>
      <ThemeContext.Provider value={value}>
        {props.children}
      </ThemeContext.Provider>
    </ThemeProvider>
  );
};

export default ThemeContextProvider;

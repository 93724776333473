import { createTheme } from "@mui/material/styles";

const customColor = {
  black1: "#1A2332",
  black2: "#222222",
  blue1: "#202B56",
  green1: "#6DBD88",
  white1: "#FFFFFF",
  white2: "#F7F7F7",
  white3: "#F2F2F2",
  white4: "#EBEBEB",
  white5: "#D8D8D8",
  white6: "#C4C4C4",
  red: "#F44336",
  red1: "#FF5252",
  red2: "#FF1744",
  red3: "#F50057",
  yellow: "#FFEB3B",
  fb: "#007aff",
};

let colors = createTheme({
  palette: {
    black1: {
      main: customColor.black1,
    },
    black2: {
      main: customColor.black2,
    },
    blue1: {
      main: customColor.blue1,
    },
    green1: {
      main: customColor.green1,
    },
    gray: {
      main: "#03A454",
    },
    white1: {
      main: customColor.white1,
    },
    white2: {
      main: customColor.white2,
    },
    white3: {
      main: customColor.white3,
    },
    white4: {
      main: customColor.white4,
    },
    white5: {
      main: customColor.white5,
    },
    white6: {
      main: customColor.white6,
    },
    red1: {
      main: customColor.red1,
    },
    red2: {
      main: customColor.red2,
    },
    red3: {
      main: customColor.red3,
    },
  },
});

export default colors;
